body {
  margin: 0;
  padding:0;
  font-family: "Roboto Flex",sans-serif;
  
}

*{
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-tap-highlight-color: tranparent;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
